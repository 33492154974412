<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-camera-campos"
    @click:outside="$emit('fechar')"
  >
    <v-card elevation="0">
      <v-toolbar color="grey lighten-3" flat dense>
        <v-toolbar-title> Adicionar/Editar Câmera </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text small @click="$emit('fechar')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="mt-4">
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="descricao">
                <v-text-field
                  v-model="cameraNova.descricao"
                  outlined
                  dense
                  hide-details="auto"
                  label="Descrição"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="identificador">
                <v-text-field
                  v-model="cameraNova.identificador"
                  outlined
                  dense
                  hide-details="auto"
                  label="Código único identificador"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="endereco_ip">
                <v-text-field
                  v-model="cameraNova.endereco_ip"
                  outlined
                  dense
                  hide-details="auto"
                  label="Endereço IP"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="endereco_mac">
                <v-text-field
                  v-model="cameraNova.endereco_mac"
                  outlined
                  dense
                  hide-details="auto"
                  label="Endereço MAC"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="tipo_apontamento">
                <v-text-field
                  v-model="cameraNova.tipo_apontamento"
                  outlined
                  dense
                  hide-details="auto"
                  label="Tipo de apontamento"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="sentido">
                <v-select
                  v-model="cameraNova.sentido"
                  :items="[
                    { id: 1, descricao: 'Entrada' },
                    { id: 2, descricao: 'Saída' },
                  ]"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  label="Sentido"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="numero_faixa">
                <v-text-field
                  v-model="cameraNova.numero_faixa"
                  outlined
                  dense
                  hide-details="auto"
                  label="Número da faixa"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>

            <v-col cols="6">
              <validation-provider v-slot="{ errors }" vid="status">
                <v-switch
                  v-model="cameraNova.status"
                  hide-details="auto"
                  class="mt-1 pt-0"
                  label="Ativo"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>

      <v-divider class="mt-4" />
      <v-card-actions>
        <v-spacer />
        <btn-salvar
          v-if="$temPermissao('camera-alteracao')"
          text
          :carregando="carregandoSalvar"
          @click="$emit('salvar', cameraNova)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    camera: {
      type: Object,
      default: () => {
        return {};
      },
    },
    mostrar: {
      type: Boolean,
      default: false,
    },
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cameraNova: {
        status: null,
      },
    };
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    camera(camera) {
      this.cameraNova = camera;
    },
  },

  methods: {},
};
</script>

<style lang="css">
.dialog-camera-campos {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dialog-camera-campos {
    width: 50% !important;
  }
}
</style>
